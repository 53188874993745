<script>
  import { onMount } from "svelte";
  import {
    nickname,
    modify_postid,
    showNavbar, refresh_access_token, mode
  } from "./store.js";
  import { navigate } from "svelte-routing";


  import Quill from "quill";
  import "quill/dist/quill.snow.css";

  let title = "";
  let content = "";
  let imageUrl = "";
  let quill;
  let category = "free";
  const modepage = $mode? '/mpk' : '/babapk' ;


  // 이미지 핸들러 커스터마이징
  function imageHandler() {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
  const file = input.files[0];
  if (!file) {
    console.error("파일이 선택되지 않았습니다.");
    return;
  }

  const reader = new FileReader();
  reader.onloadend = async () => {
    const base64Image = reader.result;
    if (!base64Image) {
      console.error("파일을 읽는 도중 문제가 발생했습니다.");
      return;
    }

    let checkjwt = localStorage.getItem("d2rpvpjwtToken");

    const uploadImage = async () => {
      const response = await fetch("/uploadimage", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${checkjwt}`,
        },
        body: JSON.stringify({ image: base64Image }),
      });

      return response;
    };

    try {
      let response = await uploadImage();

      if (response.status === 403) {
        await refresh_access_token();
        checkjwt = localStorage.getItem("d2rpvpjwtToken");
        response = await uploadImage(); // 토큰 갱신 후 다시 요청
      }

      if (!response.ok) {
        throw new Error("이미지 업로드 실패");
      }

      const data = await response.json();
      const imageUrl = data.url;

      // 이미지 URL을 에디터에 삽입
      const range = quill.getSelection();
      quill.insertEmbed(range.index, "image", imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  reader.readAsDataURL(file);
};

  }

  onMount(async () => {
    await loadModifyPostData();
    $showNavbar = false;

    quill = new Quill("#editor", {
      theme: "snow",
      placeholder: "Compose an epic...",
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline"],
            ["image", "code-block"],
          ],
          handlers: {
            image: imageHandler,
          },
        },
      },
    });

    if (content) {
      quill.clipboard.dangerouslyPasteHTML(content);
    }

    quill.on("text-change", () => {
      content = quill.root.innerHTML;
    });
  });
  const handleSubmit = async () => {
  const data = {
    title: title,
    category: category,
    content: content,
    nickname: $nickname,
    postId: $modify_postid,
  };

  let checkjwt = localStorage.getItem("d2rpvpjwtToken");

  const sendRequest = async () => {
    const response = await fetch("/writepost", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${checkjwt}`,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      return response.json();
    } else if (response.status === 403) {
      await refresh_access_token();
      checkjwt = localStorage.getItem("d2rpvpjwtToken");
      return sendRequest(); // 재귀 호출로 토큰 갱신 후 다시 시도
    } else {
      throw new Error(`Error: ${response.status}`);
    }
  };

  try {
    const responseData = await sendRequest();
    alert("글을 성공적으로 작성하였습니다");
    if (navigate) navigate(`${modepage}/boardlist`);
    modify_postid.set("none");
    showNavbar.set(true);
  } catch (error) {
    console.error("네트워크 오류:", error);
    alert(`네트워크 오류: ${error.message}`);
  }
};


  async function loadModifyPostData() {
    if ($modify_postid != "none") {
      try {
        const url = `/postget?post_id=${$modify_postid}`;

        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const loadedData = await response.json();
        title = loadedData.title;
        content = loadedData.content;
        category = loadedData.category;
      } catch (error) {
        alert("에러가 발생하였습니다");
      }
    }
  }

  function cancel() {
    modify_postid.set("none");
    navigate(`${modepage}/boardlist`);
    showNavbar.set(true);
  }
</script>

<div class="table-outline main_data">
  <div class="table-head table-title">
    <input type="text" id="title" bind:value={title} />
  </div>
  <div class="table-contents-gray">
    <div class="table-contents-no-line flex">
      <select class="namewidth" bind:value={category}>
        <option value="free">자유</option>
        <option value="trade">거래</option>
        <option value="setting">공략</option>
      </select>
    </div>
  </div>
  <div class="table-contents-white">
    <div class="table-contents-no-line flex">
      <div id="editor"></div>
    </div>
  </div>

  <div class="form-group">
    <button on:click={() => handleSubmit()}>작성하기</button>
    <button on:click={() => cancel()}>취소하기</button>
  </div>
</div>

<style>
  #editor {
    height: 60vh;
  }
  #title {
    margin: 15px;
    width: 100%;
    background-color: inherit;
    color: inherit;
    border: none;
    font-size: 1.5rem;
    padding: 5px;
  }
  .table-contents-gray div {
    background-color: gray;
  }
  .table-contents-white div {
    background-color: white;
    color: black;
  }
</style>
