<script>
  import { onMount } from "svelte";
  import {
    formatDate,
    nickname,
    modify_postid, refresh_access_token, mode
  } from "./store.js";
  import { Link } from "svelte-routing";
  import { navigate } from "svelte-routing";

  let id;
  let List_data = [];
  let loading = true;
  let errormsg = null;
  let comment = [];

  const modepage = $mode? '/mpk' : '/babapk' ;


  async function fetchPost(id) {
  try {
    const response = await fetch(`/postget?post_id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status===200) {
      const data = await response.json();
      if (data) {
        List_data = data;
        loading = false;
      } else {
        loading = false;
        errormsg = "게시물을 찾을 수 없습니다.";
      }
    } else {
      loading = false;
      errormsg = `오류 발생: ${response.status}`;
    }
  } catch (error) {
    loading = false;
    errormsg = `네트워크 오류: ${error.message}`;
    console.error("Error fetching post:", error);
  }
}


async function fetchcomment(id) {
  try {
    const response = await fetch(`/commentget?post_id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      if (data) {
        comment = data;
      } else {
        comment = []; // 댓글이 없으면 빈 배열로 초기화합니다.
      }
    } else {
      alert(`오류 발생: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching comments:", error);
    alert(`네트워크 오류: ${error.message}`);
  }
}

  function getIdFromPathname(pathname) {
    const match = pathname.match(/\/post\/(\d+)$/);
    return match ? match[1] : null;
  }
  let comment_content;
  function Handlecomment_content(event) {
    comment_content = event.target.value;
  }

  onMount(async () => {
    const pathname = window.location.pathname;
    id = getIdFromPathname(pathname);

    if (id) {
      fetchPost(id);
      fetchcomment(id);
    } else {
      loading = false;
      errormsg = "잘못된 주소입니다";
    }
  });
  async function add_comment(id) {
  let checkjwt = localStorage.getItem("d2rpvpjwtToken");
  const data = { post_id: id, content: comment_content, writter: $nickname };

  const sendCommentRequest = async () => {
    const response = await fetch("/addcomment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${checkjwt}`,
      },
      body: JSON.stringify(data),
    });

    return response;
  };

  try {
    let response = await sendCommentRequest();

    if (response.status === 200) {
      alert("댓글을 등록하였습니다");
      comment_content = ""; // 댓글 내용을 초기화
      fetchcomment(id)
    } else if (response.status === 403) {
      await refresh_access_token();
      checkjwt = localStorage.getItem("d2rpvpjwtToken");
      
      response = await sendCommentRequest(); // 토큰 갱신 후 다시 요청

      if (response.status === 200) {
        alert("댓글을 등록하였습니다");
        comment_content = ""; // 댓글 내용을 초기화
        fetchcomment(id)
      }
    } else {
      alert(`오류 발생: ${response.status}`);
    }
  } catch (error) {
    console.error("네트워크 오류:", error);
    alert(`네트워크 오류: ${error.message}`);
  }
}

async function delete_comment(post_id, id) {
  let checkjwt = localStorage.getItem("d2rpvpjwtToken");
  const data = { comment_id: id };

  const sendDeleteRequest = async () => {
    const response = await fetch("/delete_comment", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${checkjwt}`,
      },
      body: JSON.stringify(data),
    });

    return response;
  };

  try {
    let response = await sendDeleteRequest();

    if (response.status === 200) {
      alert("댓글을 삭제하였습니다");
      fetchcomment(post_id);
    } else if (response.status === 403) {
      await refresh_access_token();
      checkjwt = localStorage.getItem("d2rpvpjwtToken");
      
      response = await sendDeleteRequest(); // 토큰 갱신 후 다시 요청

      if (response.status === 200) {
        alert("댓글을 삭제하였습니다");
        fetchcomment(post_id);
      } else {
        alert(`오류 발생: ${response.status}`);
      }
    } else {
      alert(`오류 발생: ${response.status}`);
    }
  } catch (error) {
    console.error("네트워크 오류:", error);
    alert(`네트워크 오류: ${error.message}`);
  }
}


  // 수정하기 버튼을 눌렀을 때 작동할 코드
  function modify_post() {
    $modify_postid = id;
    navigate(`${modepage}/write`);
  }

// 글 삭제 함수
async function delete_post(id) {
  let checkjwt = localStorage.getItem("d2rpvpjwtToken");
  const data = { post_id: id };

  const sendDeleteRequest = async () => {
    const response = await fetch("/delete_post", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${checkjwt}`,
      },
      body: JSON.stringify(data),
    });

    return response;
  };

  try {
    let response = await sendDeleteRequest();

    if (response.status === 200) {
      alert("글을 삭제하였습니다");
      navigate("/boardlist");
    } else if (response.status === 403) {
      await refresh_access_token();
      checkjwt = localStorage.getItem("d2rpvpjwtToken");

      response = await sendDeleteRequest(); // 토큰 갱신 후 다시 요청

      if (response.status === 200) {
        alert("글을 삭제하였습니다");
        navigate("/boardlist");
      } else {
        alert(`오류 발생: ${response.status}`);
      }
    } else {
      alert(`오류 발생: ${response.status}`);
    }
  } catch (error) {
    console.error("네트워크 오류:", error);
    alert(`에러가 발생하였습니다: ${error.message}`);
  }
}
</script>

{#if loading}
  <div class="text-center">Loading...</div>
{:else if errormsg}
  <div class="text-center">Error: {errormsg}</div>
{:else}
  <div class="table-outline main_data">
    <div class="table-head table-title">{List_data.title}</div>
    <div class="table-contents-wrapper">
      <div class="table-contents-no-line flex">
        <div class="table-info-writter table-contents_cell">
          {List_data.writter}
        </div>
        <div class="table-info-date table-contents_cell">
          작성일 : {formatDate(List_data.date)}
        </div>
        <div class="table-info-date table-contents_cell">
          수정일 : {formatDate(List_data.updated)}
        </div>
        <div class="table-info-views table-contents_cell">
          조회수 : {List_data.views}
        </div>
      </div>
    </div>
    <div class="table-contents-wrapper">
      <div class="table-contents-no-line">
        <div class="table-info-content">
          {@html List_data.content}
        </div>
      </div>
    </div>
    <div class="table-contents-wrapper">
      <div class="table-contents-no-line">
        <div class="table-info-button">
          {#if List_data.writter === $nickname}
            <button on:click={() => modify_post()}>수정하기</button>{/if}
          {#if $nickname === "admin" || $nickname === "admin_m" || List_data.writter === $nickname}
            <button on:click={() => delete_post()}>삭제하기</button>
          {/if}
          <Link class="button" to={`${modepage}/boardlist`}>목록보기</Link>
        </div>
      </div>
    </div>
  </div>

  <div class="table-outline main_data">
    <div class="table-head table-title">댓글</div>
    {#each comment as { writter, content, date, comment_id }}
      <div class="table-contents-wrapper">
        <div class="table-contents-no-line flex">
          <div class="table-info-commentwritter table-contents_cell">
            {writter}
          </div>
          <div class="table-info-commentnodata table-contents_cell"></div>
          <div class="table-info-commentdate table-contents_cell">
            {#if formatDate(date) === formatDate(new Date())}new{:else}{formatDate(
                date
              )}{/if}
          </div>
        </div>
        <div class="table-contents-no-line">
          <div class="table-info-comment table-contents_cell">
            {content}
            {#if writter === $nickname || $nickname === "admin" || $nickname === "admin_m"}<button
                on:click={() => delete_comment(id, comment_id)}>삭제</button
              >{/if}
          </div>
        </div>
      </div>
    {/each}
    {#if $nickname}
      <div class="table-contents-wrapper">
        <div class="table-contents-no-line">
          <div class="table-info-comment table-contents_cell">
            <input
              class="comment_input"
              type="text"
              bind:value={comment_content}
              on:change={Handlecomment_content}
            />
            <button on:click={() => add_comment(id)}>댓글 작성</button>
          </div>
        </div>
      </div>
    {/if}
  </div>
{/if}

<style>
  .comment_input {
    width: 75%;
    border: none;
    background-color: inherit;
    color: rgb(233, 233, 233);
    font-family: inherit;
    font-size: 1.5rem;
  }

  .table-title {
    padding: 10px;
  }
  .table-info-content,
  .table-info-comment {
    height: auto;
    text-align: left;
    padding: 5px 15px;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .flex {
    display: flex;
    font-size: 1rem;
  }
  .table-info-commentwritter {
    width: 25%;
    text-align: left;
  }
  .table-info-commentdate {
    width: 20%;
    text-align: right;
  }
  .table-info-commentnodata {
    width: 55%;
  }
  .table-contents-wrapper {
    border-top: 2px white solid;
  }
  .table-info-button {
    text-align: right;
  }
</style>
