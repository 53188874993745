<script>
  import {
    mode,
    clickformopen, refresh_access_token
  } from "./store.js";

  let pw = "";
  let newemail = "";


  async function changeemail() {
  const emaildata = {
    nowpw: pw,
    newemail: newemail,
  };

  let checkjwt = localStorage.getItem("d2rpvpjwtToken");

  if (!checkjwt) {
    alert("다시 로그인해주세요");
    return;
  }

  const sendEmailChangeRequest = async () => {
    const endpoint = $mode
      ? "/process_changeemail_m"
      : "/process_changeemail";
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${checkjwt}`,
      },
      body: JSON.stringify(emaildata),
    });

    return response;
  };

  try {
    let response = await sendEmailChangeRequest();

    if (response.status === 200) {
      alert("이메일 변경 완료");
      clickformopen(null);
    } else if (response.status === 403) {
      await refresh_access_token();
      checkjwt = localStorage.getItem("d2rpvpjwtToken");

      response = await sendEmailChangeRequest(); // 토큰 갱신 후 다시 요청

      if (response.status === 200) {
        alert("이메일 변경 완료");
        clickformopen(null);
      } else {
        alert(`오류 발생: ${response.status}`);
      }
    } else {
      alert(`오류 발생: ${response.status}`);
    }
  } catch (error) {
    console.error("오류 발생:", error);
    alert("오류 발생: " + error.message);
  }
}

</script>

<div class="left">
  암호
  <input class="namewidth" type="password" bind:value={pw} />
  새 이메일
  <input class="namewidth" type="text" bind:value={newemail} />
  <button on:click={() => changeemail()}>이메일 변경</button>
</div>
