<script>
    import {
      mode,
      clickformopen, refresh_access_token,
      nickname

    } from "./store.js";
  
    let pw = "";
  
  
    async function delete_account() {
    const pwdata = {
      nowpw: pw,
    };
  
    let checkjwt = localStorage.getItem("d2rpvpjwtToken");
  
    if (!checkjwt) {
      alert("다시 로그인해주세요");
      return;
    }
  
    const deleteAccountRequest = async () => {
      const endpoint = $mode
        ? "/delete_account_m"
        : "/delete_account";
      const response = await fetch(endpoint, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${checkjwt}`,
        },
        body: JSON.stringify(pwdata),
      });
  
      return response;
    };
  
    try {
      let response = await deleteAccountRequest();
  
      if (response.status === 200) {
        alert("계정 삭제 완료");
        clickformopen(null);
        localStorage.removeItem("d2rpvpjwtToken")
localStorage.removeItem("d2rpvprefreshToken")
nickname.set(null)

      } else if (response.status === 403) {
        await refresh_access_token();
        checkjwt = localStorage.getItem("d2rpvpjwtToken");
  
        response = await deleteAccountRequest(); // 토큰 갱신 후 다시 요청
  
        if (response.status === 200) {
            alert("계정 삭제 완료");
            clickformopen(null);
            localStorage.removeItem("d2rpvpjwtToken")
localStorage.removeItem("d2rpvprefreshToken")
nickname.set(null)

        } else {
          alert(`오류 발생: ${response.status}`);
        }
      } else {
        alert(`오류 발생: ${response.status}`);
      }
    } catch (error) {
      console.error("오류 발생:", error);
      alert("오류 발생: " + error.message);
    }
  }
  
  </script>
  
  <div class="left">
   계정을 삭제하시려면 암호를 입력해주세요
    <input class="namewidth" type="password" bind:value={pw} />
    <button on:click={() => delete_account()}>계정 삭제</button>
  </div>
  