// store.js
import { writable, get } from 'svelte/store';

export const mode = writable(false);
export const formopen = writable(null);
export const currenteventname = writable("");


export function clickformopen(whatform) {
  formopen.set(whatform);
}

// JWT 디코딩을 위한 함수 (Base64Url 디코딩)
function decodeJwt(token) {
  if (typeof token === 'string' && token.includes('.')) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  } else {
    return null;
  }
}




// 세션 유효성 및 사용자 정보를 저장할 스토어
export const isAuthenticated = writable(false);

let storedToken = localStorage.getItem("d2rpvpjwtToken");
export const jwtoken = writable(storedToken);

// JWT 디코딩 및 사용자 정보 저장
export const nickname = writable(null);

// 토큰이 변경될 때마다 nickname 스토어 업데이트
jwtoken.subscribe((value) => {
  const decoded = decodeJwt(value);
  nickname.set(decoded ? decoded.username : null);
});

// 페이지가 로드되면 바로 리프레시 토큰을 이용해 JWT 갱신
refresh_access_token().then(() => {
  storedToken = localStorage.getItem("d2rpvpjwtToken");
  jwtoken.set(storedToken);
});


export async function checkSession() {
    try {
      const response = await fetch("/check-session", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },     

      });
  
      if (response.ok) {
        const data = await response.json();
        isAuthenticated.set(data.authenticated);
        nickname.set(data.user.nickname);
        return data.authenticated;
      } else {
        isAuthenticated.set(false);
        nickname.set(null);
        return false;
      }
    } catch (error) {
      console.error("Error checking session:", error);
      isAuthenticated.set(false);
      nickname.set(null);
      return false;
    }
  }
  
  
 

  // 닉네임 리스트를 저장할 store
  export const nicknames = writable([]);
  
  // 닉네임을 서버에서 가져오는 함수
  export async function fetchNicknames(modevalue) {
    try {
      const endpoint = modevalue ? "/api/getNicknames_m" : "/api/getNicknames";
      const response = await fetch(endpoint);
      if (response.ok) {
        const data = await response.json();
  
        // 닉네임 리스트 초기화 및 업데이트
        if (modevalue) {
          nicknames.set(data.map(name => name.replace("_m", "")));
        } else {
          nicknames.set(data);
        }
      } else {
        console.error('닉네임을 가져오는데 실패했습니다.');
      }
    } catch (error) {
      console.error('닉네임을 가져오는 중 오류가 발생했습니다:', error);
    }
  }
  

export const key = writable(0);


  // 날짜 포맷팅 함수
 export function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "2-digit", day: "2-digit" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  // 페이지값 기억변수
  export const page = writable(1);
  export const category = writable("all");
export const modify_postid = writable("none");


export const showNavbar = writable(true);

export async function refresh_access_token() {
  const refresh = localStorage.getItem("d2rpvprefreshToken");
  try {
    const response = await fetch('/refresh_token', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refreshToken: refresh }), // 객체로 감싸서 전송
    });
    if (response.status === 200) {
      const data = await response.json();
      localStorage.setItem("d2rpvpjwtToken", data.Token);
      storedToken = data.Token;
    } 
  } catch (error) {
    console.error('오류가 발생했습니다 :', error);
  }
}
