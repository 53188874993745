<script>
  import { onMount } from "svelte";
  import {
    nickname,
    mode,
    clickformopen,
    jwtToken
  } from "./store.js";

  let email = "Loading";
  let rscore = "Loading";
  let bscore = "Loading";
  let lscore = "Loading";
  let wins = "Loading";
  let loses = "Loading";
  let clan = "Loading";
  let clanwin = "Loading";
  let clanlose = "Loading";
let clandraw = "Loading";

  // 사용자 정보가 저장된 세션 데이터를 가져오는 함수
  async function fetchGameData() {
    const fetchData = async () => {
      const endpoint = $mode ? "/user_data_m" : "/user_data";
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "d2rpvpjwttoken": $jwtToken
        },
        credentials: "include" // httpOnly 쿠키 포함
      });

      return response;
    };

    try {
      let response = await fetchData();

      if (response.ok) {
        const data = await response.json();

        if ($mode) {
          email = data.email;
          lscore = data.lscore;
          clan = data.clan;
          clanwin = data.clanwin;
          clanlose = data.clanlose;
          clandraw = data.clandraw;
        } else {
        email = data.email;
        bscore = data.bscore;
        lscore = data.lscore;
        rscore = data.rscore;
        wins = data.countwin;
        loses = data.countlose;
      } }
    } catch (error) {
      console.error("정보를 불러오는 중 오류가 발생하였습니다:", error);
      alert("정보를 불러오는 중 오류가 발생하였습니다");
    }
  }

  onMount(() => {
    fetchGameData();
  });
</script>

<div class="outline">
  <div class="info-flexbox">
    <div class="info-name">닉네임</div>
    <div class="info-data">
      {$mode ? $nickname.replace("_m", "") : $nickname}
    </div>
  </div>
  <div class="info-flexbox">
    <div class="info-name-data">{email}</div>
  </div>
  <div class="info-flexbox">
    <div class="info-name">
      <button on:click={() => clickformopen("changepw")}>암호 변경</button>
    </div>
    <div class="info-data">
      <button on:click={() => clickformopen("changeemail")}>이메일 변경</button>
    </div>
  </div>{#if $mode} <div class="info-flexbox">
    <div class="info-name">대회점수</div>
    <div class="info-data">{lscore}</div>
  </div>
  <div class="info-flexbox">
    <div class="info-name">소속클랜</div>
    <div class="info-data">{clan}</div>
  </div>
  {#if clan==="none"} 
  <div class="info-data">
   <button on:click={() => clickformopen("clanjoin")}>클랜가입</button>
 </div>
 {/if}
  <div class="info-flexbox">
    <div class="info-name">클랜전승무패</div>
    <div class="info-data">{clanwin}/{clandraw}/{clanlose}</div>
  </div>

  {:else}
     
   <div class="info-flexbox">
    <div class="info-name">대전점수</div>
    <div class="info-data">{bscore}</div>
  </div>
  <div class="info-flexbox">
    <div class="info-name">미니대회점수</div>
    <div class="info-data">{lscore}</div>
  </div>
  <div class="info-flexbox">
    <div class="info-name">정규대회점수</div>
    <div class="info-data">{rscore}</div>
  </div>
  <div class="info-flexbox">
    <div class="info-name">승/패</div>
    <div class="info-data">{wins}/{loses}</div>
  </div>
{/if}

  <div class="info-data">
    <button on:click={() => clickformopen("deleteaccount")}>계정 탈퇴</button>
  </div>
</div>

<style>
  .outline {
    margin-top: 20px;
  }
  .info-flexbox {
    display: flex;
  }
  .info-name {
    width: 300px;
    text-align: center;
    font-size: 20px;
  }
  .info-data {
    width: 300px;
    text-align: center;
    font-size: 20px;
  }
  .info-name-data {
    text-align: center;
    width: 600px;
    font-size: 20px;
  }
</style>
