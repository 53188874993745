<script>
  import {
    mode,
    clickformopen, refresh_access_token
  } from "./store.js";
  import { onMount } from "svelte";

  let oldpw = "";
  let newpw = "";
  let checkpw = "";

  onMount(async () => {
  });

  async function changepw() {
  const pwdata = {
    nowpw: oldpw,
    newpw: newpw,
  };

  let checkjwt = localStorage.getItem("d2rpvpjwtToken");

  if (!checkjwt) {
    alert("다시 로그인해주세요");
    return;
  }

  if (newpw !== checkpw) {
    alert("암호 확인이 일치하지 않습니다 다시 확인해주세요");
    return;
  }

  const sendPasswordChangeRequest = async () => {
    const endpoint = $mode ? "/process_changepw_m" : "/process_changepw";
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${checkjwt}`,
      },
      body: JSON.stringify(pwdata),
    });

    return response;
  };

  try {
    let response = await sendPasswordChangeRequest();

    if (response.status === 200) {
      alert("암호 변경 완료");
      clickformopen(null);
    } else if (response.status === 403) {
      await refresh_access_token();
      checkjwt = localStorage.getItem("d2rpvpjwtToken");

      response = await sendPasswordChangeRequest(); // 토큰 갱신 후 다시 요청

      if (response.status === 200) {
        alert("암호 변경 완료");
        clickformopen(null);
      } else {
        alert(`오류 발생: ${response.status}`);
      }
    } else {
      alert(`오류 발생: ${response.status}`);
    }
  } catch (error) {
    console.error("오류 발생:", error);
    alert("오류 발생: " + error.message);
  }
}
</script>

<div class="left">
  암호
  <input class="namewidth" type="password" bind:value={oldpw} />
  새 암호
  <input class="namewidth" type="password" bind:value={newpw} />
  암호 확인
  <input class="namewidth" type="password" bind:value={checkpw} />
  <button on:click={() => changepw()}>암호 변경</button>
</div>
