<script>
  import { onMount } from "svelte";
  import {
    nickname,
    nicknames,
    mode,
    fetchNicknames,
    fetechClanList,
    clanlists,
    jwtToken
  } from "../store.js";

  let player;
  let playerclan;
  let playerscore;
  let clan = 'none';
  let clanscore;
let newclan = ""


  function handle_player_change(event) {
    player = validateInput(event.target.value, $nicknames);
  }

  function handle_playerclan_change(event) {
    playerclan = validateInput(event.target.value, $nicknames);
  }

  function handle_clanscore_change(event) {
    playerscore = event.target.value;
  }



  function handle_playerscore_change(event) {
    playerscore = event.target.value;
  }

  function validateInput(value, options) {
    return options.includes(value) ? value : null;
  }

  onMount(() => {
    fetchNicknames($mode);
    fetechClanList();
  });

  // 간결화된 서버 요청 함수 (토큰 관리 제거)
  async function sendRequest(endpoint, method, data = null) {
    const options = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        "d2rpvpjwttoken": $jwtToken
      },
    };

    if (data) {
      options.body = JSON.stringify(data);
    }

    const response = await fetch(endpoint, options);
    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    return response;
  }

  // 점수 부여
  async function submit_bonus_score() {
    const data = { player: player, playerScore: playerscore };
    try {
      const response = await sendRequest("/submit-admin-score_m", "POST", data);
      alert("점수 부여 완료");
    } catch (error) {
      alert("오류 발생 :", error);
    }
  }
  // 클랜소속 초기화
  async function submit_clan_reset() {
    const data = { player: playerclan };
    try {
      const response = await sendRequest("/submit-clan-reset", "POST", data);
      alert("해당 선수 클랜 리셋 완료");
    } catch (error) {
      alert("오류 발생 :", error);
    }
  }




  // 점수 초기화
  async function score_reset() {
    const userResponse = confirm(
      "모든 참가자 점수를 초기화합니다. 계속하시겠습니까?"
    );

    if (userResponse) {
      try {
        await sendRequest("/reset-rank_m", "POST");
        alert("점수를 초기화하였습니다");
      } catch (error) {
        alert("오류 발생 :", error);
      }
    }
  }


 async function submit_clan_score() {
  const data = { clan: clan, clanScore: clanscore };
    try {
      const response = await sendRequest("/admin_clan_score", "POST", data);
      alert(clan ,"클랜 점수 부여 완료");
    } catch (error) {
      alert("오류 발생 :", error);
    }
  
 }

 async function submit_clan_delete() {
  const data = {clan: clan};
  const userResponse = confirm(`${clan} 클랜을 삭제합니다. 계속하시겠습니까?`);

  if (userResponse) {
   try {
    const response = await sendRequest("/admin_clan_delete", "DELETE", data);
    alert(`${clan} 클랜 삭제 완료`);
    fetechClanList();
  } catch (error) {
      alert("오류 발생 :", error);
    }
  }
 }

 async function submit_new_clan() {
  const data = {clan: newclan};
   try {
    const response = await sendRequest("/admin_clan_create", "POST", data);
      alert(`${newclan} 클랜 추가 완료`);
      fetechClanList();
    } catch (error) {
      alert("오류 발생 :", error);
    }
   }


</script>
<svelte:head>
    <title>mpk 관리자 페이지</title>
</svelte:head>

<div class="main_data">
  {#if $nickname === "admin_m"}
    <div class="left">
      직접 점수를 부여할 선수
      <input
        list="nicknames_list"
        bind:value={player}
        on:blur={handle_player_change}
        class="namewidth"
        placeholder="선수 선택"
      />
      <datalist id="nicknames_list">
        {#each $nicknames as option}
          <option value={option}>{option}</option>
        {/each}
      </datalist>
      <input
        type="number"
        bind:value={playerscore}
        on:change={handle_playerscore_change}
        placeholder="점수"
        class="scorewidth"
      />
      <button on:click={submit_bonus_score}>점수 부여하기</button>
      <br />
      <button on:click={score_reset}>모든 점수 초기화하기</button>
      <br/> <br/>
      <div class="left">
        이 선수의 소속 클랜 초기화하기
        <input
        list="nicknames_list"
        bind:value={playerclan}
        on:blur={handle_playerclan_change}
        class="namewidth"
        placeholder="선수 선택"
      />
      <button on:click={submit_clan_reset}>클랜초기화하기</button>
      <br/> <br/>
      이 클랜에 점수 부여 / 삭제
      <select bind:value={clan} class="namewidth">
        <option value="none">클랜을 선택하세요</option>
        {#each $clanlists as option}
          <option value={option}>{option}</option>
        {/each}
      </select>
      <input
        type="number"
        bind:value={clanscore}
        on:change={handle_clanscore_change}
        placeholder="점수"
        class="scorewidth"
      />
      <button on:click={submit_clan_score}>점수 부여하기</button><br/>
      <button on:click={submit_clan_delete}>이 클랜 삭제하기</button>
      </div>
      
      <br/><br/>
      <input type="text" class="namewidth" placeholder="클랜명 입력" bind:value={newclan}>
      <button on:click={submit_new_clan}>클랜 추가하기</button><br/>


    </div>
  {:else}
    <div class="left">권한이 없습니다.</div>
  {/if}
</div>
